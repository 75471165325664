import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function App() {
  const navigate = useNavigate();

  

  function Submit(e) {
    e.preventDefault();
  
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);
  

    const mobileNumber = formDatab.get("Mobile_Number");
    if (mobileNumber.length < 10) {
      alert("Mobile number must be at least 10 digits long");
      return;
    }
  
    fetch(
      "https://script.google.com/macros/s/AKfycbw5VFeMZs2p3o4mY0Vq-SDH2JIZLoS5Rw84Ju5WtwyDdOKONrof1vJii6rrQQ-XBqCW/exec",
      {
        method: "POST",
        body: formDatab,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  
    navigate("/result");
  }


  return (
    <div className="App">
      <div className="form-face">
        <h3 className="title">
          <strong>Schedule Pickup Now</strong>
        </h3>
        <p className="header"></p>
        <div>
          <form className="form" onSubmit={(e) => Submit(e)}>
            <div className="form-starter">
              <div className="label-select">
                <label>Your Name*</label>
                <br />
                <input className="label" placeholder="Name" name="Name" type="text" required />
              </div>
              <div className="label-select">
                <label>Mobile Number*</label>
                <br />
                <input className="label" placeholder="Mobile Number" name="Mobile_Number" type="number" required />
              </div>
              <div className="label-select">
                <label>Address*</label>
                <br />
                <input className="label" placeholder="Address" name="Address" type="text" required />
              </div>

              

              <div className="label-select">
                <label>Any Note</label>
                <br />
                <input className="label-note" placeholder="Note" name="Note" type="text" />
              </div>
              <br />

              
              <div className="label-select">
                <label>
                  <input type="checkbox" name="termsAndConditions" defaultChecked required />
                  &nbsp; I agree to the&nbsp;
                  <Link to="/terms-and-conditions">Terms and Conditions</Link> {/* Use the Link component */}
                </label>
              </div>


              <div className="important-note" style={{ backgroundColor: "#adb5bd", padding: "5px", borderRadius: "10px", margin: 0 }}>
                <h4 style={{ color: "#212529" }}>Important Note</h4>
                <div>
                  <p style={{ color: "#212529", textAlign: "left", margin: 0 }}>1. Free delivery for orders over ₹350.</p>
                  <p style={{ color: "#212529", textAlign: "left", margin: 0 }}>2. Please don't include color bleeding clothes.</p>
                  <p style={{ color: "#212529", textAlign: "left", margin: 0 }}>3. Store is closed on Thursday.</p>
                  <p style={{ color: "#212529", textAlign: "left", margin: 0 }}>4. You can also include undergarments, socks, handkerchiefs.</p>
                 
                </div>
              </div>
              <br />

              

              <br />

              <input className="sch-button" type="submit" />
            </div>
          </form>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}
