import React from 'react';
import dry from '../Assets/dry_clean.png';
import shoes from '../Assets/shoe.png';
import iron from '../Assets/iron.png';
import machine from '../Assets/washing.png';
import winter from '../Assets/winter.png';
import express from '../Assets/express.png';

import { Container, Button, Card, Row, Col } from 'react-bootstrap';



const Main = () => {

    return (
        <div id='home' className='App'>
           

            <Container id='pricing'>
                <Row>
                    <Col>
                    <Card style={{ width: '19rem', marginLeft: '0%', marginTop: '4%' }}>
                            <img
                                src={machine}
                                width="60"
                                height="60"
                                className="img"
                                alt="machine"
                            />
                            <Card.Body >
                                <Card.Title className='title-large'><b>NORMAL LAUNDRY</b></Card.Title>
                                <Card.Title className='title-price'>
                                    WASH AND FOLD - ₹60/KG
                                    WASH AND IRON - ₹90/KG
                                    
                                </Card.Title>
                                
                                <Card.Title className='title-small'><b>✓ 3-5 DAYS DELIVERY</b></Card.Title>
                                <Card.Title className='title-small'><b>✓ SEPERATE WASH FOR EACH CUSTOMER</b></Card.Title>
                              
                           
                                <a href='/schedule'>
                                    <Button className='s-button'>SCHEDULE PICKUP</Button>
                                </a>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                    <Card style={{ width: '19rem', marginLeft: '0%', marginTop: '4%' }}>
                            <img
                                src={express}
                                width="60"
                                height="60"
                                className="img"
                                alt="cloth"
                            />
                            <Card.Body >
                                <Card.Title className='title-large'><b>EXPRESS LAUNDRY</b></Card.Title>
                                <Card.Title className='title-price'>
                                    WASH AND FOLD - ₹100/KG
                                    WASH AND IRON - ₹125/KG
                                 
                                </Card.Title>
                                <Card.Title className='title-small'><b>✓ COLLAR AND CUFF CLEANING</b></Card.Title>
                                <Card.Title className='title-small'><b>✓ NEXT DAY DELIVERY</b></Card.Title>
                                <Card.Title className='title-small'><b>✓ SEPERATE WASH FOR EACH CUSTOMER</b></Card.Title>
                                <Card.Title className='title-small'><b>✓ TUMBLER DRIED</b></Card.Title>
                                
                                <a href='/schedule'>
                                    <Button className='s-button'>SCHEDULE PICKUP</Button>
                                </a>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                    <Card style={{ width: '19rem', marginLeft: '0%', marginTop: '4%' }}>
                            <img
                                src={winter}
                                width="60"
                                height="60"
                                className="img"
                                alt="winter"
                            />
                            <Card.Body >
                                <Card.Title className='title-large'><b>WOOLEN LAUNDRY</b></Card.Title>
                                <Card.Title className='title-price'>
                                    WASH AND IRON - ₹170/KG
                                 
                                </Card.Title>
                                
                                <Card.Title className='title-small'><b>✓ SEPERATE WASH FOR EACH CUSTOMER</b></Card.Title>
                                <Card.Title className='title-small'><b>✓ TUMBLER DRIED</b></Card.Title>
                                <Card.Title className='title-small'><b>✓ WOOL SAFE DETERGENT</b></Card.Title>
                            
                                <a href='/schedule'>
                                    <Button className='s-button'>SCHEDULE PICKUP</Button>
                                </a>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '19rem', marginLeft: '0%', marginTop: '4%' }}>
                            <img
                                src={shoes}
                                width="60"
                                height="60"
                                className="img"
                                alt="shoes"
                            />
                            <Card.Body>
                                <Card.Title className='title-large'><b>SHOE CLEAN</b></Card.Title>
                                <Card.Title className='title-price'>
                                    ONE PAIR OF SHOES - ₹90
                                </Card.Title>
                               
                               
                                <a href='/schedule'>
                                    <Button className='s-button'>SCHEDULE PICKUP</Button>
                                </a>
                            </Card.Body>
                        </Card>
                    </Col>
                
                    <Col>
                        <Card style={{ width: '19rem', marginLeft: '0%', marginTop: '4%' }}>
                            <img
                                src={dry}
                                width="60"
                                height="60"
                                className="img"
                                alt="dry"
                            />
                            <Card.Body>
                                <Card.Title className='title-large'><b>DRY CLEAN</b></Card.Title>
                                <Card.Title className='title-small'><b>Duppata : ₹50</b></Card.Title>
                                <Card.Title className='title-small'><b>Pant/Shirt/T-Shirt/Kurta : ₹150</b></Card.Title>
                                <Card.Title className='title-small'><b>Salwar : ₹150</b></Card.Title>
                                <Card.Title className='title-small'><b>Waist Coat/Sweater : ₹150</b></Card.Title>
                                <Card.Title className='title-small'><b>Curtain Panel : ₹180</b></Card.Title>
                                <Card.Title className='title-small'><b>Jacket/Hoodie : ₹200</b></Card.Title>
                                <Card.Title className='title-small'><b>Saree : ₹200</b></Card.Title>
                                <Card.Title className='title-small'><b>Coat : ₹250</b></Card.Title>
                                <Card.Title className='title-small'><b>Achkan : ₹350</b></Card.Title>
                                <Card.Title className='title-small'><b>Long Coat : ₹380</b></Card.Title>
                                <Card.Title className='title-small'><b>Leather Jacket : ₹400</b></Card.Title>
                                <Card.Title className='title-small'><b>Light Blanket/Quilt : ₹300</b></Card.Title>
                                <Card.Title className='title-small'><b>Heavy Blanket/Quilt : ₹400</b></Card.Title>
                                
                                <a href='/schedule'>
                                    <Button className='s-button'>SCHEDULE PICKUP</Button>
                                </a>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card style={{ width: '19rem', marginLeft: '0%', marginTop: '4%' }}>
                            <img
                                src={iron}
                                width="60"
                                height="60"
                                className="img"
                                alt="iron"
                            />
                            <Card.Body>
                                <Card.Title className='title-large'><b>STEAM IRON</b></Card.Title>
                                <Card.Title className='title-price'>
                                ONE PIECE - ₹10
                                    
                                </Card.Title>
                                
                                
                                <a href='/schedule'>
                                    <Button className='s-button'>SCHEDULE PICKUP</Button>
                                </a>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
            <br /><br /><br />
        </div>
    )
}

export default Main